<template lang="">
    <div class="image-card d-inline-flex flex-column justify-content-center p-3">
        <h5>{{ image_type ? $t(`document_images.${image_type}`) : "" }}</h5>
        <div>
            <img class="my-3" fluid :src="imageSrc" style="max-width: 400px" />
        </div>

        <div>
            <b-button variant="primary" @click="upload"
                >{{ $t("image_upload") }}
                <i class="ri-upload-line ml-2" style="font-size: 16px"></i
            ></b-button>
            <b-form-file
                v-show="false"
                :placeholder="$t('select_file')"
                :drop-placeholder="$t('drop_file')"
                ref="fileInput"
                accept=".png,.jpeg,.ico"
                @input="input"
            >
            </b-form-file>
        </div>
    </div>
</template>

<script>

import SystemLogoService from "@/services/SystemLogoService";

export default {
    name: "ImageCard",
    props: {
        image_type: {
            type: String,
            default: "",
        },
        value: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {};
    },
    computed: {
        imageSrc: {
            get() {
                const type = this.image_type === "favicon" ? "x-icon" : "png";
                return (
                    this.beforeBase64(type) +
                    this.value[
                    `${this.image_type === "background"
                        ? "login-background"
                        : this.image_type
                    }.${this.image_type == "favicon" ? "ico" : "png"}`
                    ]
                );
            },
            set(val) {
                if (!val) return
                const strIndex = val.indexOf("base64,");

                const newObj = {};
                newObj[
                    `${this.image_type === "background"
                        ? "login-background"
                        : this.image_type
                    }.${this.image_type == "favicon" ? "ico" : "png"}`
                ] = val.substring(strIndex + 7);
                this.$emit("input", { ...this.value, ...newObj });
            },
        },
    },
    methods: {
        async input(file) {
            if (!file) return
            this.imageSrc = await this.base64Encode(file);

            const formData = new FormData();
            formData.append("type", this.image_type);
            formData.append("logo", file);

            const response = await SystemLogoService.logoUpload(formData).catch(
                (error) => {
                    this.showErrors(error);
                }
            );
            this.$toast.success(this.$t("api." + response.data.message));
            this.$refs["fileInput"].reset();
        },
        upload() {
            this.$refs.fileInput.$el.children[0].click();
        },
        beforeBase64(type) {
            return `data:image/${type};base64,`;
        },
    },
};
</script>

<style lang="scss">
.image-card {
    border: 1px solid rgba(10, 15, 6, 0.2);
    border-radius: 5px;
    max-width: 450px;
}
</style>
